<template>
   <ObiCard :hideBody="!isEditing">
      <template #header @click="isEditing = !isEditing">
         <ObiTitle :icon="RuleCardIcon" :title="title">
            <template #actions>
               <ObiCol class="d-flex">
                  <ObiButton compact size="lg" color="lighter" :icon="EditIcon" @click="isEditing = !isEditing" />
                  <v-popover>
                     <ObiButton class="ms-2" size="lg" compact color="lighter" :icon="DeleteIcon" />

                     <template slot="popover">
                        <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_delete')" />
                        <ObiButton
                           class="ms-2"
                           size="sm"
                           color="danger"
                           :text="$t('actions.yes')"
                           v-close-popover
                           @click="deleteItem"
                        />
                        <ObiButton class="ms-2" size="sm" color="warning" :text="$t('actions.no')" v-close-popover />
                     </template>
                  </v-popover>
               </ObiCol>
            </template>
         </ObiTitle>
      </template>
      <template #body class="p-4">
         <ObiLoading v-if="isLoading" />
         <ObiRow v-else class="p-4">
            <ObiCol col="6">
               <ObiSelect
                  @input="onChanged"
                  v-model="formDataRule.state"
                  :label="$t('fields.state')"
                  :options="stateSelectOptions"
                  :placeholder="$t('fields.state')"
               />
            </ObiCol>
            <ObiCol col="6">
               <ObiSelect
                  @input="onChanged"
                  :disabled="!formDataRule.state"
                  v-model="formDataRule.action"
                  :label="$t('fields.action')"
                  :options="actionSelectOptions"
                  :placeholder="$t('fields.action')"
               />
            </ObiCol>
            <ObiCol v-if="formDataRule.action && formDataRule.action === 'redirect_to_survey'">
               <ObiSelect
                  @input="onChanged"
                  v-model="formDataRule.survey_id"
                  :label="$t('fields.survey')"
                  :options="surveyOptions"
                  :placeholder="$t('fields.survey')"
               />
            </ObiCol>
            <ObiCol v-if="formDataRule.action && formDataRule.action === 'show_ad'">
               <ObiSelect
                  @input="onChanged"
                  v-model="formDataRule.ad_id"
                  :disabled="isLoadingAds"
                  :label="$t('fields.ad')"
                  :options="adOptions"
                  :placeholder="$t('fields.ad')"
               />
            </ObiCol>
            <ObiCol v-if="formDataRule.action && formDataRule.action === 'redirect_to_url'">
               <ObiInput
                  @input="onChanged"
                  v-model="formDataRule.target_url"
                  :label="$t('fields.target_url')"
                  :placeholder="$t('fields.target_url')"
               />
            </ObiCol>
            <ObiCol col="12" class="text-center mt-4">
               <ObiButton size="lg" wider :text="$t('actions.save')" @click="saveOrUpdate" />
            </ObiCol>
         </ObiRow>
      </template>
   </ObiCard>
</template>

<script>
import { keys, omit } from 'lodash';

import RuleCardIcon from '@/components/Icons/portals/RuleCardIcon';
import EditIcon from '@/components/Icons/portals/EditIcon.svg';
import DeleteIcon from '@/components/Icons/portals/DeleteIcon.svg';
import IconExternalLink from '@/components/Icons/general/IconExternalLink.svg';

import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';
import PanelPortalApi from '@/api/PanelPortalApi';

import Form from '@/libs/form';

export default {
   name: 'ObiRuleCard',
   i18n: {
      messages: {
         tr: {
            last_seen: 'Son Görülme',
            are_you_sure_delete: 'Silmek istediğinize emin misiniz?',
            fields: {
               state: 'Durum',
               action: 'Aksiyon',
               survey: 'Anket',
               target_url: 'Hedef Adres',
               ad: 'Reklam',
            },
            actions: {
               yes: 'Evet',
               no: 'Hayır',
               save: 'Kaydet',
            },
         },
         en: {
            last_seen: 'Last Seen',
            are_you_sure_delete: 'Are you sure you want to delete?',
            fields: {
               state: 'Status',
               action: 'Action',
               survey: 'Survey',
               target_url: 'Target URL',
               ad: 'Advertisement',
            },
            actions: {
               yes: 'Yes',
               no: 'No',
               save: 'Save',
            },
         },
      },
   },
   props: {
      portalId: {
         type: [Number, String],
         required: true,
      },
      rule: {
         type: Object,
      },
      index: {
         type: Number,
      },
      surveys: {
         type: Array,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      title() {
         if (this.formDataRule.action) {
            let title = '';
            if (this.formDataRule.state === 'before_login') {
               title = 'Girişten Önce ';
            } else if (this.formDataRule.state === 'login_successful') {
               title = 'Giriş Başarılı ';
            } else if (this.formDataRule.state === 'login_failed') {
               title = 'Giriş Başarısız ';
            }
            title += ' -> ';
            if (this.formDataRule.action === 'redirect_to_survey') {
               title += 'Ankete Yönlendir';
            } else if (this.formDataRule.action === 'redirect_to_url') {
               title += "URL'ye Yönlendir";
            } else if (this.formDataRule.action === 'show_ad') {
               title += 'Reklam Göster';
            }

            return title;
         }

         return 'Yeni Kural';
      },
      surveyOptions() {
         return this.$props.surveys.map((survey) => ({ label: survey.name, value: survey.id }));
      },
      adOptions() {
         return this.dataAds.map((ad) => ({ label: ad.name, value: ad.id }));
      },
   },
   mounted() {
      this.formDataRule.merge(this.$props.rule);
      this.getAds();
   },
   data() {
      return {
         IconExternalLink,
         RuleCardIcon,
         EditIcon,
         DeleteIcon,
         formDataRule: Form.create(['state', 'action', 'survey_id', 'ad_id', 'target_url']).validate({
            name: 'required',
            action: 'required',
            target_url: 'required_if:action,redirect_to_url',
            survey_id: 'required_if:action,redirect_to_survey',
            ad_id: 'required_if:action,show_ad',
         }),
         isEditing: false,
         isLoading: false,
         isLoadingAds: false,
         dataAds: [],
         stateSelectOptions: [
            {
               label: 'Girişten Önce',
               value: 'before_login',
            },
            {
               label: 'Giriş Başarılı',
               value: 'login_successful',
            },
            {
               label: 'Giriş Başarısız',
               value: 'login_failed',
            },
         ],
         actionSelectOptions: [
            {
               label: 'Ankete Yönlendir',
               value: 'redirect_to_survey',
            },
            {
               label: "URL'ye Yönlendir",
               value: 'redirect_to_url',
            },
            {
               label: 'Reklam Göster',
               value: 'show_ad',
            },
         ],
      };
   },
   methods: {
      async saveOrUpdate() {
         if (!this.formDataRule.isValid()) return;

         this.isLoading = true;
         try {
            if (this.item) {
               await PanelPortalApi.rules.update(this.portalId, this.item.id, this.formDataRule.toObject());
               Notify.success(this.$t('Kural güncellendi!'));
            } else {
               await PanelPortalApi.rules.store(this.portalId, this.formDataRule.toObject());
               Notify.success(this.$t('Kural eklendi!'));
            }

            this.$emit('changed', this.formDataRule);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async getAds() {
         try {
            this.isLoadingAds = true;

            const { data } = await PanelPortalApi.ads.index(this.portalId, null);
            this.dataAds = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingAds = false;
         }
      },
      async onChanged() {
         this.$emit('change', this.formDataRule);
      },
      async deleteItem() {
         this.isLoading = true;
         try {
            if (this.formDataRule.id === undefined || this.formDataRule.id === null) {
               console.log(this.$props.index);
               this.$emit('deleted', this.$props.index);
               return;
            }
            await PanelPortalApi.rules.delete(this.portalId, this.formDataRule.id);
            Notify.success(this.$t('Kural silindi!'));
            this.$emit('deleted', null);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async addNewRule() {
         this.$emit('added', this.formDataRule);
      },
   },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/variables';

.obi-dcm {
   padding: 20px;
   display: grid;
   grid-gap: 20px;
   border-radius: 8px;
   align-items: center;
   background-color: #f3f8fe;
   justify-content: space-between;
   grid-template-columns: minmax(120px, max-content) 1fr auto;

   .obi-dcm-image {
      text-align: center;

      .obi-image {
         width: unset;
         max-width: 90px;
         max-height: 45px;
         object-fit: cover;
         object-position: center;
      }
   }

   .obi-dcm-content {
      display: grid;
   }

   .obi-dcm-name {
      color: $primary;
      font-size: 1.25rem;
      @include text-truncate;
   }

   .obi-dcm-location {
      font-size: 0.8rem;
   }

   .obi-dcm-status-icon {
      width: 12px;
      height: 12px;
      border-radius: 12px;

      &.online {
         background: #31ba3f;
      }

      &.offline {
         background: #ff8e8e;
      }
   }

   .obi-dcm-action {
   }
}
</style>
